(function() {
  'use strict';

  // Your custom JavaScript goes here

  var $ = jQuery.noConflict();
  jQuery(document).ready(function($) {

    // animsition
    $(".animsition").animsition({
      inClass: 'fade-in',
      outClass: 'fade-out',
      inDuration: 1440,
      outDuration: 1440,
      linkElement: '.animsition-link',
      // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
      loading: true,
      loadingParentElement: 'body', //animsition wrapper element
      loadingClass: 'animsition-loading',
      loadingInner: '', // e.g '<img src="loading.svg" />'
      timeout: false,
      timeoutCountdown: 5000,
      onLoadEvent: true,
      browser: ['animation-duration', '-webkit-animation-duration'],
      // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
      // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
      overlay: false,
      overlayClass: 'animsition-overlay-slide',
      overlayParentElement: 'body',
      transition: function(url) {
        window.location.href = url;
      }
    });

    var topNavbar = $('#top-navbar'),
        topNavbarHeight = $('#top-navbar').outerHeight(),
        welcomeAnimation = $('#welcome-animation').outerHeight();

        // hash = window.location.hash.substr(1);
        // console.log(hash);

    // Make Navbar stickey to top
    // AdjustHeader(); // Incase the user loads the page from halfway down (or something);
    // $(window).scroll(function() {
    //   AdjustHeader();
    // });

    // function AdjustHeader() {
    //     if ($('#welcome-animation').length > 0) {
    //         if ($(window).scrollTop() > welcomeAnimation) {
    //           if (!topNavbar.hasClass('fixed-top')) {
    //             topNavbar.addClass('fixed-top');
    //             $('main').css('margin-top', topNavbarHeight);
    //           }
    //         } else {
    //           topNavbar.removeClass('fixed-top');
    //           $('main').css('margin-top', 0);
    //         }
    //     } else {
    //         topNavbar.addClass('fixed-top');
    //         $('main').css('margin-top', topNavbarHeight);
    //     };
    // }

    // August 15, 2017 - made change due to jumpy sticky navbar on mobile
    // https://stackoverflow.com/questions/28864210/sticky-navigation-element-jumps-during-scroll
    var navPosition = topNavbar.offset().top;
    $(window).scroll(function() {
        // get scroll position from top of the page
        var scrollPosition = $(this).scrollTop();

        // check if scroll position is >= the nav position
        if (scrollPosition >= navPosition) {
            topNavbar.addClass('fixed-top');
            $('main').css('margin-top', topNavbarHeight);
        } else {
            topNavbar.removeClass('fixed-top');
            $('main').css('margin-top', 0);
        }
    });

    if(window.location.hash) {
        var hash = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
        // alert (hash);
          topNavbar.addClass('fixed-top');
          $('main > section').addClass('anchored');
        //   $('#welcome-animation, .scrollable-arrow').remove();
          $('main').css('margin-top', topNavbarHeight);
    } else {
        // No hash found
    }

    // Close Navbar onclick {
    $('.navbar-nav a').on('click', function() {
      $('.navbar-toggler').click();
    });

    // Smooth Scroll for Navbar links
    $('body').scrollspy({
        target: '#top-navbar',
        offset: topNavbarHeight
    });

    $(window).on('activate.bs.scrollspy', function () {
        if ($('#welcome-animation').length > 0) {
            // $('#welcome-animation, .scrollable-arrow').remove();
        };
    })

    $(".scrollable-arrow a[href^='#'], .nav-item a[href^='#']").on('click', function(e) {
        if (this.hash !== '') {
            e.preventDefault();
            var hash = this.hash;
            $('main > section').removeClass('anchored');

            $('html, body').animate({
                scrollTop: $(this.hash).offset().top - topNavbarHeight
                // scrollTop: $(this.hash).offset().top
            }, 960, function(){
                window.location.hash = hash;
            });
        }
    });

    // iphone-inline-video
    var background_video_mobile = $('#welcome_video_mobile').get(0);
    if ($('#welcome_video_mobile').length > 0) {
        enableInlineVideo(
            background_video_mobile, {
                iPad: true
            }
        );
    };

    // Get width and height of browser
    if ($(window).width() > $(window).height()) {
        $('body').addClass('landscapeMode').removeClass('portraitMode');
    } else if ($(window).width() < $(window).height()) {
        $('body').addClass('portraitMode').removeClass('landscapeMode');
    } else {
        $('body').removeClass('landscapeMode').removeClass('portraitMode');
    }


    $('.welcome-carousel').slick({
      dots: false,
      infinite: true,
      speed: 1920,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      autoplaySpeed: 4800,
      arrows: false,
      adaptiveHeight: false
    });

    $('.expertise-carousel').slick({
      dots: false,
      infinite: false,
      speed: 960,
      slidesToScroll: 1,
      slidesToShow: 3,
      centerMode: false,
      variableWidth: true,
      adaptiveHeight: false
    });

    var $status = $('.slide-info');
    var $projectGalleryCarousel = $('.project-gallery-carousel');

    $projectGalleryCarousel.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
        var slideNumber = (currentSlide ? currentSlide : 0) + 1;
        var slideTotal = slick.slideCount;

        if(slideNumber.toString().length < 2) slideNumber = '0' + slideNumber;
        if(slideTotal.toString().length < 2) slideTotal = '0' + slideTotal;

        $status.text(slideNumber + '/' + slideTotal);
    });

    $projectGalleryCarousel.slick({
      dots: false,
      infinite: true,
      speed: 960,
      slidesToScroll: 1,
      slidesToShow: 3,
      centerMode: false,
      variableWidth: true,
      adaptiveHeight: false
    });

    var $expertiseGalleryCarousel = $('.expertise-carousel'),
        $expertiseGalleryHeight = $('.expertise-carousel').outerHeight();


    if ($($expertiseGalleryCarousel).length > 0) {
        // alert($expertiseGalleryHeight);
        // if ($(window).width() <= 767) {
           $('#expertise .expertise-item').css('height', $expertiseGalleryHeight);
        // }
    }

    $expertiseGalleryCarousel.on('afterChange', function(event, slick, currentSlide, nextSlide) {
        var slideTotal = slick.slideCount;

        //If we're on the first slide hide the Previous button and show the Next
        if (currentSlide === 0) {
          $('.slick-prev').hide();
          $('.slick-next').show();
        }
        else {
        	$('.slick-prev').show();
        }

        if ($(window).width() >= 1200) {
           if (currentSlide === slideTotal - 3 ? $('.slick-next').hide() : $('.slick-next').show());
        }
        else if ($(window).width() >= 768) {
           if (currentSlide === slideTotal - 2 ? $('.slick-next').hide() : $('.slick-next').show());
        }
      });


    // $('.expertise-carousel, .project-gallery-carousel').on('afterChange', function() {
    //   var currentSlide = $('.expertise-carousel, .project-gallery-carousel').slick('slickCurrentSlide');
    //   var total_articles = tickerDiv.slideCount;
    //
    //   if (currentSlide === 0) {
    //     $('.slick-prev').hide();
    //   }
    //
    //   if (currentSlide > 0) {
    //     $('.slick-prev').show();
    //     $('.slick-next').show();
    //   }
    // });
    //

    $('.slick-lazy').slick({
      lazyLoad: 'ondemand',
      slidesToShow: 3,
      slidesToScroll: 1
    });

    // Gallery Video
    if ($('.gallery-video').length) {
      $('.gallery-video').parent().click(function() {
        if ($(this).children('video').get(0).paused) {
          $(this).children('video').get(0).play();
          $(this).children('.video-play-pause').fadeOut();
        } else {
          $(this).children('video').get(0).pause();
          $(this).children('.video-play-pause').fadeIn();
        }
      });

      $('.slick-arrow').click(function() {
        $('.gallery-video').get(0).pause();
        $('.video-play-pause').fadeIn();
      });
    };
  });

})();
